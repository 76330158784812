var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-tree-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("空间位置")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "page-body page-body-margin" },
          [
            _c(
              "div",
              { staticClass: "page-table-search" },
              [
                _c("a-input-search", {
                  attrs: { placeholder: "请输入名称搜索", "allow-clear": "" },
                  on: { change: _vm.onSearchValueChange },
                  model: {
                    value: _vm.searchValue,
                    callback: function($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue"
                  }
                })
              ],
              1
            ),
            _c(
              "jtl-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.addClick()
                  }
                }
              },
              [_vm._v("添加建筑")]
            ),
            _c("a-tree", {
              staticClass: "space-tree",
              attrs: {
                "default-expand-all": true,
                "expanded-keys": _vm.treeExpandedKeys,
                "show-line": true,
                "tree-data-simple-mode": "",
                "tree-node-filter-prop": "title",
                "tree-data": _vm.treeData
              },
              on: {
                "update:expandedKeys": function($event) {
                  _vm.treeExpandedKeys = $event
                },
                "update:expanded-keys": function($event) {
                  _vm.treeExpandedKeys = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "custom",
                  fn: function(item) {
                    return [
                      item.isEdit
                        ? _c(
                            "span",
                            [
                              _c("a-input", {
                                ref: "title-" + item.uuid,
                                staticClass: "tree-item-input-name",
                                attrs: {
                                  id: "title-" + item.uuid,
                                  "default-value": item.title,
                                  size: "small",
                                  placeholder:
                                    "请输入" + _vm.getLevelDesc(item.level),
                                  "allow-clear": ""
                                },
                                on: {
                                  keydown: [
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.saveClick(item)
                                    },
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "esc",
                                          27,
                                          $event.key,
                                          ["Esc", "Escape"]
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.cancelClick(item)
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              item.level === 2
                                ? _c("a-input-number", {
                                    ref: "sort-" + item.uuid,
                                    staticStyle: {
                                      "vertical-align": "top",
                                      width: "120px"
                                    },
                                    attrs: {
                                      id: "sort-" + item.uuid,
                                      "default-value": item.sort,
                                      size: "small",
                                      placeholder: "楼层"
                                    },
                                    on: {
                                      keydown: [
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.saveClick(item)
                                        },
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "esc",
                                              27,
                                              $event.key,
                                              ["Esc", "Escape"]
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.cancelClick(item)
                                        }
                                      ]
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "jtl-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    loading: item.btnLoading,
                                    "click-prop": {
                                      callback: _vm.saveClick.bind(this),
                                      param: item
                                    }
                                  }
                                },
                                [_vm._v("保存")]
                              ),
                              _vm._v(" "),
                              _c(
                                "jtl-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelClick(item)
                                    }
                                  }
                                },
                                [_vm._v("取消")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !item.isEdit,
                              expression: "!item.isEdit"
                            }
                          ]
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getHighLightHtml(
                                  item.title,
                                  _vm.searchValue
                                )
                              )
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-desc" }, [
                            _vm._v(_vm._s(_vm.getLevelDesc(item.level))),
                            item.level === 2
                              ? _c("span", [_vm._v(": " + _vm._s(item.sort))])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !item.isEdit,
                              expression: "!item.isEdit"
                            }
                          ],
                          staticClass: "tree-item-action-wrapper"
                        },
                        [
                          item.level === 1
                            ? _c(
                                "a",
                                {
                                  staticClass: "jtl-link",
                                  on: {
                                    click: function($event) {
                                      return _vm.addLabel(item)
                                    }
                                  }
                                },
                                [_vm._v("添加标签")]
                              )
                            : _vm._e(),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          item.level < 4
                            ? _c(
                                "a",
                                {
                                  staticClass: "jtl-link",
                                  on: {
                                    click: function($event) {
                                      return _vm.addClick(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "添加" +
                                      _vm._s(_vm.getLevelDesc(item.level + 1))
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              staticClass: "jtl-edit-link",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.editClick(item)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认删除?",
                                "ok-text": "确认",
                                "cancel-text": "取消"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.deleteClick(item)
                                }
                              }
                            },
                            [
                              _c("a", { staticClass: "jtl-del-link" }, [
                                _vm._v("删除")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("space-tree-add-label-dialog", {
        ref: "spaceTreeAddLabelDialog",
        on: { dialogOK: _vm.formDialogOK }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }